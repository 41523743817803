import { render, staticRenderFns } from "./ChildMemoryFormDialog.vue?vue&type=template&id=577ce9d7&scoped=true&"
import script from "./ChildMemoryFormDialog.vue?vue&type=script&lang=js&"
export * from "./ChildMemoryFormDialog.vue?vue&type=script&lang=js&"
import style0 from "./ChildMemoryFormDialog.vue?vue&type=style&index=0&id=577ce9d7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "577ce9d7",
  null
  
)

/* custom blocks */
import block0 from "./ChildMemoryFormDialog.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VCol,VDatePicker,VForm,VIcon,VImg,VMenu,VProgressCircular,VRow,VSkeletonLoader,VTextField,VTextarea})
