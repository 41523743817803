<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="dialog_title"
    v-on:submit="save"
  >
    <template v-slot:content>
      <v-form ref="form" v-model="form" lazy-validation>
        <v-menu
          v-model="date_menu"
          :close-on-content-click="false"
          :nudge-top="30"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :label="$t('Date')"
              :rules="[(v) => !!v || $t('DateRequired')]"
              v-model="progress.date"
              :disabled="is_saving"
              append-icon="$vuetify.icons.fasCalendarAlt"
              v-on="on"
              outlined
              required
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            :max="new Date().toISOString().substr(0, 10)"
            :min="child.birth"
            v-model="progress.date"
            @input="date_menu = false"
          ></v-date-picker>
        </v-menu>

        <v-text-field
          :label="$t('Title')"
          :rules="[(v) => !!v || $t('TitleIsMissing')]"
          v-model="progress.header"
          :disabled="is_saving"
          counter
          maxlength="50"
          :placeholder="$t('TitleExample')"
          outlined
          required
        >
        </v-text-field>
        <v-textarea
          :disabled="is_saving"
          no-resize
          outlined
          class="mt-2"
          rows="3"
          v-model="progress.description"
          :label="$t('Description')"
        ></v-textarea>
      </v-form>
      <error-box :error="error"></error-box>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "Title": "Title",
    "Date": "Date",
    "DateRequired": "Date is required!",
    "TitleIsMissing": "Title is missing.",
    "AddProgress": "Add progress",
    "EditProgress": "Edit progress",
    "Description": "Description",
    "FirstTooth": "First tooth",
    "FirstToothText": "Got his first tooth this day",
    "Crawling": "Started crawling",
    "CrawlingText": "Began to crawl this day",
    "Walk": "Started walking",
    "WalkText": "Began to walk this day",
    "TitleExample": "Started crawling, walking ..."
    },
    "sv": {
    "Title": "Titel",
    "Date": "Date",
    "DateRequired": "Datum saknas!",
    "TitleIsMissing": "Titel måste anges.",
    "AddProgress": "Lägg till framsteg",
    "EditProgress": "Redigera framsteg",
    "Description": "Beskrivning",
    "FirstTooth": "Fick sin första tand",
    "FirstToothText": "Fick denna dag sin första tand",
    "Crawling": "Började krypa",
    "CrawlingText": "Började krypa denna dag",
    "Walk": "Började gå",
    "WalkText": "Tog sina första steg denna dag",
    "TitleExample": "Började krypa, gå..."
    }
    }
</i18n>

<script>
//import { mapState } from 'vuex'
import { childrenService } from "../../../_services";
import * as moment from "moment";

export default {
  name: "diary_album_form_dialog",
  props: {
    child: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    is_open: false,
    form: false,
    error: null,
    is_saving: false,
    date_menu: false,
    dialog_title: "",

    progress: {
      id: null,
      date: "",
      header: "",
      description: "",
    },
    sample: [],
  }),
  methods: {
    open() {
      this.setSample();
      this.dialog_title = this.$t("AddProgress");
      this.is_open = true;
      this.progress.date = moment().format("YYYY-MM-DD");
    },
    close() {
      this.progress = {
        id: 0,
        date: "",
        header: "",
        description: "",
      };
      this.$refs.form.reset();
      this.is_open = false;
    },
    edit(item) {
      this.open();
      this.progress = { ...item };
      this.dialog_title = this.$t("EditProgress");
    },
    save() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        var req;

        if (self.progress.id) {
          req = childrenService.updateEvent(self.progress.id, self.progress);
        } else {
          req = childrenService.createEvent(self.child.id, self.progress);
        }

        req
          .then(function () {
            self.is_saving = false;
            self.is_open = false;

            if (self.progress.id) {
              self.$emit("updated", self.progress);
            } else {
              self.$emit("created", self.progress);
            }
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
    setSample() {
      this.sample = [
        { title: this.$t("Crawling"), text: this.$t("CrawlingText") },
        { title: this.$t("Walk"), text: this.$t("WalkText") },
        { title: this.$t("FirstTooth"), text: this.$t("FirstToothText") },
      ];
    },
    useSample(item) {
      this.progress.header = item.title;
      this.progress.description = item.text;
    },
  },
};
</script>