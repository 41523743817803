<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="$t('Growth')"
    v-on:submit="save"
  >
    <template v-slot:content>
      <v-form ref="form" v-model="form" lazy-validation autocomplete="off">
        <v-menu
          v-model="date_menu"
          :close-on-content-click="false"
          :nudge-right="0"
          :nudge-top="30"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :label="$t('Date')"
              :rules="[(v) => !!v || $t('DateRequired')]"
              readonly
              v-model="size.date"
              :disabled="is_saving"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
              append-icon="$vuetify.icons.fasCalendarAlt"
              v-on="on"
              required
              outlined
            ></v-text-field>
          </template>
          <v-date-picker
            :max="new Date().toISOString().substr(0, 10)"
            :min="child.birth"
            v-model="size.date"
            @input="date_menu = false"
          ></v-date-picker>
        </v-menu>

        <v-text-field
          :label="$t('Length')"
          v-model="size.length"
          required
          autocorrect="off"
          spellcheck="false"
          autocomplete="off"
          :disabled="is_saving"
          outlined
          :rules="[
            (v) => !!v || $t('LengthRequired'),
            (v) => /^[0-9.,]+$/.test(v) || $t('LengthNotValid'),
          ]"
          :suffix="diary.length_format"
        ></v-text-field>

        <v-text-field
          :label="$t('Weight')"
          v-model="size.weight"
          required
          autocorrect="off"
          spellcheck="false"
          autocomplete="off"
          :disabled="is_saving"
          outlined
          :rules="[
            (v) => !!v || $t('WeightRequired'),
            (v) => /^[0-9.,]+$/.test(v) || $t('WeightNotValid'),
          ]"
          :suffix="diary.weight_format"
        ></v-text-field>

        <v-text-field
          :label="$t('Headsize')"
          v-model="size.headsize"
          required
          autocorrect="off"
          spellcheck="false"
          autocomplete="off"
          :disabled="is_saving"
          outlined
          :rules="[
            (v) => !!v || $t('HeadsizeRequired'),
            (v) => /^[0-9.,]+$/.test(v) || $t('HeadsizeNotValid'),
          ]"
          :suffix="diary.length_format"
        ></v-text-field>

        <v-textarea
          :label="$t('Note')"
          :disabled="is_saving"
          no-resize
          counter
          maxlength="150"
          rows="3"
          outlined
          v-model="size.note"
        ></v-textarea>
      </v-form>
      <error-box :error="error"></error-box>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "Growth": "Growth",
    "LengthWeight": "Growth",
    "Date": "Date",
    "Length": "Length",
    "Weight": "Weight",
    "Note": "Note",
    "DateRequired": "Date is required!",
    "LengthRequired": "Length is required!",
    "WeightRequired" : "Weight is required!",
    "ChildSizeNotFound": "Post not found!",
    "SuccessCreateSize": "Saved!",
    "Headsize": "Head circumference",
    "HeadsizeRequired": "Head circumference is missing!",
    "LengthNotValid": "Length not valid. Use 0-9 , .",
    "WeightNotValid": "Weight not valid. Use 0-9 , .",
    "HeadsizeNotValid": "Head circumference not valid. Use 0-9 , ."
    },
    "sv": {
    "Growth": "Tillväxt",
    "LengthWeight": "Tillväxt",
    "Date": "Datum",
    "Length": "Längd",
    "Weight": "Vikt",
    "Note": "Notis",
    "DateRequired": "Datum saknas!",
    "LengthRequired": "Längd saknas!",
    "WeightRequired" : "Vikt saknas!",
    "ChildSizeNotFound": "Posten kunde inte hittas!",
    "SuccessCreateSize": "Sparat!",
    "Headsize": "Huvudomfång",
    "HeadsizeRequired": "Huvudomfång saknas!",
    "LengthNotValid": "Längd är inte korrekt. Använd 0-9 , .",
    "WeightNotValid": "Vikt är inte korrekt. Använd 0-9 , .",
    "HeadsizeNotValid": "Huvudomfång är inte korrekt. Använd 0-9 , ."
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { childrenService } from "../../../_services";
import * as moment from "moment";

export default {
  name: "diary_child_growth_dialog",
  props: {
    child: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    is_open: false,
    form: false,
    date_menu: false,

    error: null,
    is_saving: false,

    size: {
      id: null,
      date: "",
      length: "",
      weight: "",
      note: "",
    },
    is_edit_mode: false,
  }),
  methods: {
    ...mapActions({}),

    open() {
      this.is_open = true;
      this.is_edit_mode = false;
      this.size.date = moment().format("YYYY-MM-DD");
    },
    edit(data) {
      this.size = { ...data };
      this.is_open = true;
      this.is_edit_mode = true;
    },
    close() {
      this.$refs.form.reset();
      this.is_open = false;
    },
    save() {
      if (this.is_edit_mode) this.update();
      else this.create();
    },
    update() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        childrenService
          .updateSize(self.size.id, self.size)
          .then(function () {
            self.is_saving = false;
            self.is_open = false;
            self.$emit("updated", { ...self.size });
            self.$refs.form.reset();
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
    create() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        childrenService
          .createSize(self.child.id, self.size)
          .then(function (response) {
            self.is_saving = false;
            self.is_open = false;
            self.$emit("created", response.data);
            self.$refs.form.resetValidation();
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
  },
};
</script>