var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-dialog',{attrs:{"is-saving":_vm.is_saving,"title":_vm.$t('Growth')},on:{"submit":_vm.save},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-form',{ref:"form",attrs:{"lazy-validation":"","autocomplete":"off"},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":0,"nudge-top":30,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('Date'),"rules":[function (v) { return !!v || _vm.$t('DateRequired'); }],"readonly":"","disabled":_vm.is_saving,"autocorrect":"off","spellcheck":"false","autocomplete":"off","append-icon":"$vuetify.icons.fasCalendarAlt","required":"","outlined":""},model:{value:(_vm.size.date),callback:function ($$v) {_vm.$set(_vm.size, "date", $$v)},expression:"size.date"}},on))]}}]),model:{value:(_vm.date_menu),callback:function ($$v) {_vm.date_menu=$$v},expression:"date_menu"}},[_c('v-date-picker',{attrs:{"max":new Date().toISOString().substr(0, 10),"min":_vm.child.birth},on:{"input":function($event){_vm.date_menu = false}},model:{value:(_vm.size.date),callback:function ($$v) {_vm.$set(_vm.size, "date", $$v)},expression:"size.date"}})],1),_c('v-text-field',{attrs:{"label":_vm.$t('Length'),"required":"","autocorrect":"off","spellcheck":"false","autocomplete":"off","disabled":_vm.is_saving,"outlined":"","rules":[
          function (v) { return !!v || _vm.$t('LengthRequired'); },
          function (v) { return /^[0-9.,]+$/.test(v) || _vm.$t('LengthNotValid'); } ],"suffix":_vm.diary.length_format},model:{value:(_vm.size.length),callback:function ($$v) {_vm.$set(_vm.size, "length", $$v)},expression:"size.length"}}),_c('v-text-field',{attrs:{"label":_vm.$t('Weight'),"required":"","autocorrect":"off","spellcheck":"false","autocomplete":"off","disabled":_vm.is_saving,"outlined":"","rules":[
          function (v) { return !!v || _vm.$t('WeightRequired'); },
          function (v) { return /^[0-9.,]+$/.test(v) || _vm.$t('WeightNotValid'); } ],"suffix":_vm.diary.weight_format},model:{value:(_vm.size.weight),callback:function ($$v) {_vm.$set(_vm.size, "weight", $$v)},expression:"size.weight"}}),_c('v-text-field',{attrs:{"label":_vm.$t('Headsize'),"required":"","autocorrect":"off","spellcheck":"false","autocomplete":"off","disabled":_vm.is_saving,"outlined":"","rules":[
          function (v) { return !!v || _vm.$t('HeadsizeRequired'); },
          function (v) { return /^[0-9.,]+$/.test(v) || _vm.$t('HeadsizeNotValid'); } ],"suffix":_vm.diary.length_format},model:{value:(_vm.size.headsize),callback:function ($$v) {_vm.$set(_vm.size, "headsize", $$v)},expression:"size.headsize"}}),_c('v-textarea',{attrs:{"label":_vm.$t('Note'),"disabled":_vm.is_saving,"no-resize":"","counter":"","maxlength":"150","rows":"3","outlined":""},model:{value:(_vm.size.note),callback:function ($$v) {_vm.$set(_vm.size, "note", $$v)},expression:"size.note"}})],1),_c('error-box',{attrs:{"error":_vm.error}})]},proxy:true}]),model:{value:(_vm.is_open),callback:function ($$v) {_vm.is_open=$$v},expression:"is_open"}})}
var staticRenderFns = []

export { render, staticRenderFns }