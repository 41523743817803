<template>
  <v-container class="child-growth-admin" v-if="is_admin">
    <v-skeleton-loader :loading="page.is_loading" type="list-item-three-line">
      <div>
        <div class="text-right mb-7">
          <v-btn color="primary" class="text-none" text @click="create">
            {{ $t("New") }}
          </v-btn>
        </div>

        <v-simple-table v-if="items && items.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("Date") }}</th>
                <th class="text-left">{{ $t("Length") }}</th>
                <th class="text-left">{{ $t("Weight") }}</th>
                <th class="text-left">{{ $t("HeadSize") }}</th>
                <th class="text-right"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.date }}</td>
                <td>{{ item.length + " " + item.length_format }}</td>
                <td>{{ item.weight + " " + item.weight_format }}</td>
                <td>{{ item.headsize + " " + item.length_format }}</td>
                <td class="text-right">
                  <button-menu
                    fab
                    small
                    depressed
                    :items="getItemMenu(item)"
                    btn-icon="$vuetify.icons.dotsHorizontal"
                    :is-loading="item.is_deleting"
                  ></button-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div
          v-if="!page.error && (!items || items.length == 0)"
          class="text-center"
        >
          {{ $t("Empty") }}
        </div>
        <form-dialog
          v-on:created="onCreated"
          v-on:updated="onUpdated"
          :child="child"
          ref="dialog"
        ></form-dialog>
        <error-page :error="page.error" v-if="page.error"></error-page>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Date": "Date",
    "Length": "Length",
    "Weight": "Weight",
    "HeadSize": "Head circumference",
    "New": "Add growth",
    "Edit": "Edit growth",
    "Delete": "Delete growth",
    "ConfirmDeleteSize": "Are you sure you want to delete this post?",
    "Empty": "There is no items to show!"
    },
    "sv": {
    "Date": "Datum",
    "Length": "Längd",
    "Weight": "Vikt",
    "HeadSize": "Huvudomfång",
    "New": "Lägg till tillväxt",
    "Delete": "Ta bort tillväxt",
    "Edit": "Redigera tillväxt",
    "ConfirmDeleteSize": "Är du säker på att du vill ta bort detta?",
    "Empty": "Det finns inga poster registrerade!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { childrenService } from "@/_services";
import FormDialog from "@/components/diary/dialogs/AdminChildGrowth.vue";

export default {
  name: "diary_growth_admin",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "form-dialog": FormDialog,
  },
  data: () => ({
    page: {
      is_loading: false,
      error: null,
    },

    items: [],

    child: {},
  }),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }
    var urlName = this.$route.params.name;
    var temp = this.$store.getters["diary/children/getChildByUrlName"](urlName);

    if (temp) {
      this.child = temp;
      this.loadGrowth();
    } else {
      this.page.error = this.$t("ChildNotFound");
    }
  },
  methods: {
    ...mapActions({}),
    getItemMenu(item) {
      var self = this;
      var data = [];

      data.push({
        name: this.$t("Edit"),
        icon: "$vuetify.icons.fileDocumentEditOutline",
        click: function () {
          self.edit(item);
        },
      });

      data.push({
        name: this.$t("Delete"),
        icon: "$vuetify.icons.trashCanOutline",
        click: function () {
          self.deleteItem(item);
        },
      });

      return data;
    },
    loadGrowth() {
      var self = this;
      self.page.is_loading = true;
      self.page.error = null;

      childrenService
        .getSizes(self.child.id)
        .then(function (response) {
          self.items = response.data;
          self.page.is_loading = false;
          self.orderItems();
        })
        .catch(function (error) {
          self.page.error = error;
          self.page.is_loading = false;
        });
    },
    async deleteItem(item) {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeleteSize"));

      if (res) {
        self.$set(item, "is_deleting", true);

        childrenService
          .deleteSize(item.id)
          .then(function () {
            self.items = self.items.filter((post) => post.id !== item.id);
            self.$store.dispatch("diary/timeline/reset", null, { root: true });
            item.is_loading = false;
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            item.is_loading = false;
          });
      }
    },
    edit(item) {
      this.$refs.dialog.edit(item);
    },
    create() {
      this.$refs.dialog.open();
    },
    onCreated(item) {
      this.items.push(item);
      this.orderItems();
      this.$store.dispatch("diary/timeline/reset", null, { root: true });
    },
    onUpdated(data) {
      this.items = [
        ...this.items.filter((element) => element.id !== data.id),
        data,
      ];
      this.orderItems();
      this.$store.dispatch("diary/timeline/reset", null, { root: true });
    },
    orderItems() {
      this.items.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      });
    },
  },
};
</script>