<template>
  <v-container class="diary-form-page text-center" v-if="is_admin">
    <h1>{{ $t("DeleteChild") }}</h1>
    <p class="mt-3">
      {{ $t("ConfirmDeleteChild") }}
      <b>{{ child.name | capitalize }}</b>?
    </p>
    <p class="body-2" v-html="$t('DeleteDesc')"></p>

    <error-box :error="error" class="mb-3 mt-3"></error-box>

    <v-btn
      color="error"
      class="mt-10 text-none"
      @click="confirmDelete"
      :loading="is_deleting"
    >{{ $t("Delete") }}</v-btn>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Title": "Delete child",
    "DeleteChild": "Delete child?",
    "ConfirmDeleteChild": "Are you sure you want to delete ",
    "SuccessDelete": "Child deleted!",
    "DeleteDesc": "When you delete this child all progress and growth will be deleted for this child.<br />Note, this can't be restored when deleted!",
    "Delete": "Yes, delete"
    },
    "sv": {
    "Title": "Ta bort",
    "DeleteChild": "Ta bort barnet?",
    "ConfirmDeleteChild": "Är du säker på att du vill ta bort ",
    "SuccessDelete": "Barnet togs bort!",
    "DeleteDesc":"När du tar bort detta barn kommer även all data rörande barnets utveckling samt tillväxt att tas bort.<br />Notera att detta ej går att återställa.",
    "Delete": "Ja, ta bort"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "diary_child_delete",
  metaInfo() {
    return {
      title: this.getTitle()
    };
  },
  computed: {
    ...mapState({
      user: state => state.account.user,
      diary: state => state.diary.profile,
      is_admin: state => state.diary.is_admin

    })
  },
  data: () => ({
    child: {},
    is_deleting: false,
    error: null
  }),
  created: function() {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }

    this.child = this.$store.getters["diary/children/getChildByUrlName"](
      this.$route.params.name
    );
  },
  methods: {
    ...mapActions({
      deleteChild: "diary/children/delete"
    }),
    getTitle() {
      return this.$t("Title");
    },
    confirmDelete() {
      var self = this;
      self.is_deleting = true;

      self
        .deleteChild(self.child.id)
        .then(function() {
          self.$successNoty(self.$t("SuccessDelete"));
          self.$router.push("/" + self.diary.name);
        })
        .catch(function(error) {
          self.error = error;
          self.is_deleting = false;
        });
    }
  }
};
</script>