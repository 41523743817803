<template>
  <form-dialog
    v-model="dialog.is_open"
    :is-saving="is_saving"
    :title="memory_type.title"
    v-on:submit="save"
    max-width="800px"
    :hide-submit="dialog.error || dialog.is_loading"
  >
    <template v-slot:content>
      <v-skeleton-loader
        :loading="dialog.is_loading"
        type="list-item-three-line"
        v-if="dialog.is_open"
      >
        <div class="pt-5">
          <v-form
            ref="form"
            v-model="form"
            v-if="!dialog.error"
            lazy-validation
            autocomplete="off"
          >
            <v-row>
              <v-col cols="12" class="pb-5 pt-0"
                >{{ memory_type.description }}
              </v-col>

              <v-col cols="12" sm="12" md="6" class="pb-1 pt-0">
                <v-menu
                  v-model="date_menu"
                  :close-on-content-click="false"
                  :nudge-top="30"
                  :disabled="!can_edit_date"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :label="memory_type.form.date.label"
                      :rules="[(v) => !!v || $t('DateRequired')]"
                      v-model="post.date"
                      :disabled="is_saving || !can_edit_date"
                      append-icon="$vuetify.icons.fasCalendarAlt"
                      v-on="on"
                      outlined
                      required
                      readonly
                      autocorrect="off"
                      spellcheck="false"
                      autocomplete="off"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="post.date"
                    :max="maxDate"
                    :min="minDate"
                    @input="date_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="6"
                class="pb-0 pt-0"
                v-if="memory_type.form.place"
              >
                <v-text-field
                  :label="memory_type.form.place.label"
                  v-model="post.place"
                  :disabled="is_saving"
                  autocorrect="off"
                  spellcheck="false"
                  autocomplete="off"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                class="pb-0 pt-0"
                v-if="memory_type.form.estimated_birth"
              >
                <v-menu
                  v-model="estimated_birth_menu"
                  :close-on-content-click="false"
                  :nudge-top="30"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :label="$t('EstimatedBirth')"
                      v-model="post.data.estimated_birth"
                      :disabled="is_saving"
                      autocorrect="off"
                      append-icon="$vuetify.icons.fasCalendarAlt"
                      v-on="on"
                      readonly
                      spellcheck="false"
                      autocomplete="off"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="post.data.estimated_birth"
                    @input="estimated_birth_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" class="pb-0 pt-2">
                <v-textarea
                  outlined
                  :label="$t('TellMore')"
                  v-model="post.text"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" class="pb-0 pt-0">
                <div class="form-header">{{ $t("Images") }}</div>
                <div class="caption" v-html="$t('ImagesDesc')"></div>

                <div class="image-list mt-3">
                  <ul>
                    <draggable
                      v-model="post.media"
                      @start="drag = true"
                      @end="drag = false"
                      draggable=".media-item"
                    >
                      <li
                        v-for="item in post.media"
                        :key="item.id"
                        class="media-item"
                      >
                        <v-card height="75" outlined @click="removeImage(item)">
                          <v-img
                            :src="item.image.thumb_150"
                            height="75"
                            class="grey lighten-2"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                      </li>
                    </draggable>

                    <li v-if="post.media.length < 5">
                      <v-card
                        height="75"
                        width="75"
                        @click="openGallery"
                        class="text-center grey--text text--darken-1"
                        outlined
                      >
                        <v-icon size="30" class="mt-3"
                          >$vuetify.icons.plus</v-icon
                        >
                        <div
                          class="caption text-center pt-1 font-weight-medium"
                        >
                          {{ $t("AddMedia") }}
                        </div>
                      </v-card>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>

            <media-picker
              v-if="diary"
              v-on:done="selectedMedia"
              ref="mediaPicker"
              :diary="diary.name"
              :max="5 - post.media.length"
            ></media-picker>
          </v-form>
          <error-box :error="dialog.error"></error-box></div
      ></v-skeleton-loader>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "DateRequired": "Date is missing",
    "EstimatedBirth": "Estimated birth",
    "Date": "Date",
    "AddMedia": "Add",
    "TellMore": "Describe",
    "Images": "Images",
    "ImagesDesc": "Add up to 5 images. The first one will be used as a cover. Drag the image to change order. <br />Click on image to remove it from list.",
    "SuccessSave": "Information saved!"
    },
    "sv": {
    "DateRequired": "Datum saknas",
    "EstimatedBirth": "Beräknad födsel",
    "Date": "Datum",
    "AddMedia": "Lägg till",
    "TellMore": "Berätta mer",
    "Images": "Bilder",
    "ImagesDesc": "Välj upp till 5 bilder. Den första bilden kommer användas som huvudbild. <br />Du kan ändra ordningen genom att dra bilden. Du tar bort en bild genom att klicka på den.",
    "SuccessSave": "Informationen sparades!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import MediaPicker from "../../../components/diary/dialogs/MediaPickerDialog.vue";
import { childrenService } from "../../../_services";
import draggable from "vuedraggable";
import { childMemories } from "../../../_helpers";
import * as moment from "moment";

function initialState() {
  return {
    tinymceApiKey: process.env.VUE_APP_TINYMCE_KEY,

    memory_type: {
      form: {},
    },

    dialog: {
      is_open: false,
      is_loading: false,
      error: null,
    },

    is_edit: false,
    is_saving: false,
    error: null,
    form: false,
    date_menu: false,
    estimated_birth_menu: false,
    can_edit_date: true,

    post: {
      date: "",
      place: "",
      text: "",
      media: [],

      data: {
        estimated_birth: "",
      },
    },

    editor: {
      height: 200,
      menubar: false,
      plugins: ["advlist autolink lists link charmap", "paste"],
      toolbar: "undo redo | " + " bold italic underline | bullist numlist",
      content_css: [
        "//fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
      ],
    },
  };
}

export default {
  name: "child_memory_form_dialog",
  props: {
    child: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
    maxDate: function () {
      if (this.memory_type.key == "pregnancy")
        return moment(this.child.birth).add(-1, "d").format("YYYY-MM-DD");
      return moment().format("YYYY-MM-DD");
    },
    minDate: function () {
      if (this.memory_type.key == "pregnancy")
        return moment(this.child.birth).add(-1, "y").format("YYYY-MM-DD");

      return moment(this.child.birth).format("YYYY-MM-DD");
    },
  },
  components: {
    "media-picker": MediaPicker,
    draggable,
  },
  data: function () {
    return initialState();
  },
  methods: {
    ...mapActions({
      create: "diary/childprofile/createMemory",
      update: "diary/childprofile/updateMemory",
    }),
    createItem(type) {
      Object.assign(this.$data, initialState());
      this.dialog.is_open = true;
      this.memory_type = type;

      this.setMemoryTypeInfo();
    },
    editItem(id) {
      Object.assign(this.$data, initialState());
      this.dialog.is_open = true;
      this.load(id);
    },
    setMemoryTypeInfo() {
      if (this.memory_type.key == "birth") {
        this.post.date = this.child.birth;
        this.can_edit_date = false;
      } else if (this.memory_type.key == "birthday") {
        this.post.date = moment(this.child.birth)
          .add(this.memory_type.years, "y")
          .format("YYYY-MM-DD");
        this.can_edit_date = false;
      } else if (this.memory_type.key == "pregnancy" && !this.post.date) {
        this.post.date = moment(this.child.birth)
          .add(-1, "d")
          .format("YYYY-MM-DD");
      }
    },
    setData(item) {
      this.post.date = item.date;
      this.post.text = item.text;
      this.post.place = item.place;
      this.post.media = item.media;
      this.post.data = item.data;
      this.post.id = item.id;
      this.memory_type = childMemories
        .getList()
        .find((p) => p.id == item.type_id);

      this.setMemoryTypeInfo();

      if (!this.post.media) this.post.media = [];
    },
    load(id) {
      var self = this;
      self.dialog.error = null;
      self.dialog.is_loading = true;
      self.is_edit = true;

      childrenService
        .getMemoryById(id)
        .then(function (response) {
          self.setData(response.data);
          self.dialog.is_loading = false;
        })
        .catch(function (error) {
          self.dialog.error = error;
          self.dialog.is_loading = false;
        });
    },
    save() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;
        var data = self.getSaveData();

        if (!self.is_edit) {
          self
            .create({ key: self.memory_type.key, data: data })
            .then(function (response) {
              self.$successNoty(self.$t("SuccessSave"));

              self.$router.push(
                "/" +
                  self.diary.name +
                  "/child/" +
                  self.child.url_name +
                  "/memories/" +
                  response.data.url_name
              );
            })
            .catch(function (error) {
              self.$ajaxErrorNoty(error);
              self.is_saving = false;
            });
        } else {
          self
            .update({ id: self.post.id, data: data })
            .then(function () {
              self.$successNoty(self.$t("SuccessSave"));
              self.$emit("updated");
              self.is_saving = false;
            })
            .catch(function (error) {
              self.$ajaxErrorNoty(error);
              self.is_saving = false;
            });
        }
      }
    },
    getSaveData() {
      var postData = {
        date: this.post.date,
        text: this.post.text,
        place: this.post.place,
        data: {},
        media: [],
        url_name: null,
      };

      if (this.memory_type.form.estimated_birth) {
        postData.data.estimated_birth = this.post.data.estimated_birth;
      }

      for (var i = 0; i < this.post.media.length; i++) {
        postData.media.push(this.post.media[i].id);
      }

      if (this.memory_type.url_name) {
        postData.url_name = this.memory_type.url_name;
      }

      return postData;
    },
    openGallery() {
      this.$refs.mediaPicker.open();
    },
    selectedMedia(items) {
      if (items.length > 0) {
        for (var i = 0; i < items.length; i++) {
          var obj = items[i];
          var exists = this.post.media.find((p) => p.id == obj.id);

          if (!exists) this.post.media.push(obj);
        }
      }
    },
    removeImage(image) {
      this.post.media = this.post.media.filter((p) => p.id != image.id);
    },
  },
};
</script>

<style scoped>
.image-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.image-list ul li {
  float: left;
  width: 75px;
  height: 75px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.form-header {
  font-weight: bold;
  font-size: 14px;
}
</style>