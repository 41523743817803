<template>
  <form-dialog
    v-model="is_open"
    :title="title"
    v-on:submit="done"
    :submit-text="$t('Button.Done')"
    max-width="800px"
    content-class="max-400"
    :submit-disabled="!selected_medias || selected_medias.length == 0"
  >
    <template v-slot:top v-if="selected_album">
      <v-card-subtitle v-if="media.page > 0" class="pb-2 pt-2">
        <v-btn
          text
          small
          @click="selected_album = null"
          color="primary"
          class="ml-n2 text-none"
        >
          <v-icon left small>$vuetify.icons.fasArrowLeft</v-icon>
          {{ $t("BackToAlbums") }}
        </v-btn>
      </v-card-subtitle>
    </template>

    <template v-slot:content>
      <div v-if="!selected_album">
        <v-skeleton-loader
          :loading="is_loading"
          transition="scale-transition"
          max-width="300"
          type="card"
        >
          <div>
            <template v-if="!error">
              <v-row v-if="albums && albums.length > 0">
                <v-col
                  xs="6"
                  md="3"
                  cols="6"
                  v-for="item in albums"
                  :key="item.id"
                >
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 2 : 0"
                      @click="selected_album = item.id"
                      height="220"
                      outlined
                    >
                      <v-img
                        :src="$imagehelpers.mediaThumb(item.image)"
                        height="150"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-card-title class="pa-3">
                        <h2 class="subtitle-2 albums-header">
                          {{ item.title | capitalize }}
                        </h2>
                      </v-card-title>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>

              <div
                class="text-center pt-10"
                v-if="!albums || albums.length == 0"
              >
                {{ $t("NoAlbums") }}
              </div>
            </template>

            <error-box :error="error"></error-box>
          </div>
        </v-skeleton-loader>
      </div>

      <div v-else>
        <v-skeleton-loader
          :loading="media.is_loading && media.unit_pages == 0"
          transition="scale-transition"
          max-width="300"
          type="card"
        >
          <div>
            <template v-if="!media.error">
              <v-row v-if="media.items && media.items.length > 0" class="mt-n2">
                <v-col
                  xs="6"
                  md="3"
                  cols="6"
                  v-for="item in media.items"
                  :key="item.id"
                >
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      height="150"
                      @click="imageClick(item)"
                      :class="{ 'selected-media': isSelected(item) }"
                    >
                      <v-img
                        :src="$imagehelpers.mediaThumb(item.image)"
                        height="150"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                        <div
                          v-if="hover"
                          class="grey darken-3 v-card--reveal white--text"
                          style="
                            height: 30px;
                            margin-top: 120px;
                            padding-left: 10px;
                            padding-top: 5px;
                          "
                        >
                          {{ item.title }}
                        </div>
                      </v-img>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col
                  class="text-center mt-5 mb-5"
                  xs="12"
                  cols="12"
                  v-if="media.unit_pages > media.page"
                >
                  <v-btn
                    @click="loadMedias"
                    text
                    color="primary"
                    :loading="media.is_loading"
                    class="text-none"
                    >{{ $t("LoadMore") }}</v-btn
                  >
                </v-col>
              </v-row>
              <div
                class="text-center pt-5"
                v-if="!media.items || media.items.length == 0"
              >
                {{ $t("NoImagesInAlbum") }}
              </div>
            </template>
            <error-box :error="media.error"></error-box>
          </div>
        </v-skeleton-loader>
      </div>
    </template>

    <template v-slot:footer>
      <span class="caption"
        >{{ $t("Selected") }} {{ selected_medias.length }} {{ $t("Off") }}
        {{ max }}</span
      >
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "SelectImages": "Select images",
    "SelectImage": "Select image",
    "Albums": "Load images from album:",
    "NoImagesInAlbum": "There is no images in this album!",
    "SelectAlbum": "Select album to load images.",
    "LoadMore": "Load more",
    "Selected": "Selected",
    "Off": "off maximum",
    "NoAlbums": "No albums to show!",
    "BackToAlbums": "Back to albums"
    },
    "sv": {
    "SelectImages": "Välj bilder",
    "SelectImage": "Välj bild",
    "Albums": "Visa bilder från album:",
    "NoImagesInAlbum": "Det finns inga bilder i det valda albumet!",
    "SelectAlbum": "Välj ett album för att ladda bilder!",
    "LoadMore": "Hämta fler",
    "Selected": "Antal valda",
    "Off": "av maximalt",
    "NoAlbums": "Det finns inga album att visa!",
    "BackToAlbums": "Tillbaka till album"
    }
    }
</i18n>

<script>
import { albumsService } from "../../../_services";

export default {
  name: "diary_media_picker_dialog",
  props: {
    diary: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  computed: {
    title: function () {
      if (this.max > 1) {
        return this.$t("SelectImages");
      } else return this.$t("SelectImage");
    },
  },

  data: () => ({
    is_open: false,
    error: null,
    is_loading: false,

    albums: [],

    media: {
      is_loading: false,
      error: null,
      items: [],
      page: 0,
      unit_pages: 0,
    },

    selected_album: null,

    selected_medias: [],

    height: "",
  }),
  methods: {
    open() {
      this.height = "height:" + window.innerHeight / 2 + "px";
      this.is_open = true;
      this.selected_album = null;
      this.loadAlbums();
    },
    loadAlbums() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      albumsService
        .albumList(self.diary)
        .then(function (response) {
          self.is_loading = false;
          self.albums = response.data;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    loadMedias() {
      var self = this;
      self.media.is_loading = true;
      self.media.error = null;

      albumsService
        .getAlbumMedia(self.selected_album, self.media.page + 1)
        .then(function (response) {
          var data = response.data;
          for (var i = 0; i < data.data.length; i++) {
            self.media.items.push(data.data[i]);
          }

          self.media.unit_pages = data.unit_pages;
          self.media.page = data.current_page;

          self.media.is_loading = false;
        })
        .catch(function (error) {
          self.media.is_loading = false;
          self.media.error = error;
        });
    },
    isSelected(item) {
      return (
        this.selected_medias.filter((element) => element.id == item.id) != ""
      );
    },
    imageClick(image) {
      var self = this;

      if (self.isSelected(image)) {
        self.selected_medias = [
          ...self.selected_medias.filter((element) => element.id !== image.id),
        ];
      } else {
        if (self.selected_medias.length >= self.max) {
          self.selected_medias.pop();
        }

        self.selected_medias.push(image);
      }
    },
    done() {
      this.$emit("done", this.selected_medias);
      this.selected_album = null;
      this.is_open = false;
    },
    close() {
      this.is_open = false;
      this.selected_album = null;
      this.$emit("closed");
    },
  },
  watch: {
    selected_album: function (val) {
      if (val) {
        this.media.items = [];
        this.media.page = 0;
        this.loadMedias();
      }
    },
  },
};
</script>

<style scoped>
.selected-media {
  border: solid 2px green;
  opacity: 0.5;
}
.selected-media .v-image {
  height: 146px !important;
}
.back-link {
  color: blue;
  cursor: pointer;
}
.back-link:hover {
  text-decoration: underline;
}
</style>