<template>
  <v-container class="child-progress-page" v-if="is_admin">
    <v-skeleton-loader :loading="page.is_loading" type="list-item-three-line">
      <div>
        <div class="text-right mb-7">
          <v-btn text color="primary" class="text-none" @click="create">
            {{ $t("New") }}
          </v-btn>
        </div>

        <v-simple-table v-if="items && items.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" style="width: 110px">{{ $t("Date") }}</th>
                <th class="text-left">{{ $t("Header") }}</th>
                <th class="text-right"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.date }}</td>
                <td>{{ item.header }}</td>
                <td class="text-right">
                  <button-menu
                    fab
                    small
                    depressed
                    :items="getItemMenu(item)"
                    btn-icon="$vuetify.icons.dotsHorizontal"
                    :is-loading="item.is_deleting"
                  ></button-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div
          v-if="!page.error && (!items || items.length == 0)"
          class="text-center"
        >
          {{ $t("Empty") }}
        </div>
        <form-dialog
          v-on:created="onCreated"
          v-on:updated="onUpdated"
          :child="child"
          ref="dialog"
        ></form-dialog>
        <error-page :error="page.error" v-if="page.error"></error-page>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Date": "Date",
    "New": "Add progress",
    "Edit": "Edit progress",
    "Delete": "Delete progress",
    "ConfirmDeleteProgress": "Are you sure you want to delete this post?",
    "Empty": "There is no items to show!",
    "Progress": "Progress",
    "SuccessDelete": "Post deleted!",
    "Header": "Header"
    },
    "sv": {
    "Date": "Datum",
    "New": "Lägg till framsteg",
    "Edit": "Redigera framsteg",
    "Delete": "Ta bort framsteg",
    "ConfirmDeleteProgress": "Är du säker på att du vill ta bort denna post?",
    "Empty": "Det finns inga poster registrerade!",
    "Progress": "Framsteg",
    "SuccessDelete": "Posten togs bort!",
    "Header": "Rubrik"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { childrenService } from "@/_services";
import FormDialog from "@/components/diary/dialogs/AdminChildProgress.vue";

export default {
  name: "diary_progress_admin",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "form-dialog": FormDialog,
  },
  data: () => ({
    page: {
      is_loading: false,
      error: null,
    },

    items: [],

    child: {},
  }),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }
    var urlName = this.$route.params.name;
    var temp = this.$store.getters["diary/children/getChildByUrlName"](urlName);

    if (temp) {
      this.child = temp;
      this.loadProgress();
    } else {
      this.page.error = this.$t("ChildNotFound");
    }
  },
  methods: {
    ...mapActions({}),
    getItemMenu(item) {
      var self = this;
      var data = [];

      data.push({
        name: this.$t("Edit"),
        icon: "$vuetify.icons.fileDocumentEditOutline",
        click: function () {
          self.edit(item);
        },
      });

      data.push({
        name: this.$t("Delete"),
        icon: "$vuetify.icons.trashCanOutline",
        click: function () {
          self.deleteItem(item);
        },
      });

      return data;
    },
    loadProgress() {
      var self = this;
      self.page.is_loading = true;
      self.page.error = null;

      childrenService
        .getEvents(self.child.id)
        .then(function (response) {
          self.items = response.data;
          self.page.is_loading = false;
          self.orderItems();
        })
        .catch(function (error) {
          self.page.error = error;
          self.page.is_loading = false;
        });
    },
    async deleteItem(item) {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeleteProgress"));

      if (res) {
        self.$set(item, "is_deleting", true);

        childrenService
          .deleteEvent(item.id)
          .then(function () {
            self.items = self.items.filter((post) => post.id !== item.id);
            self.$store.dispatch("diary/timeline/reset", null, { root: true });
            self.$successNoty(self.$t("SuccessDelete"));
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            item.is_deleting = false;
          });
      }
    },
    edit(item) {
      this.$refs.dialog.edit(item);
    },
    create() {
      this.$refs.dialog.open();
    },
    onCreated(item) {
      this.items.push(item);
      this.$store.dispatch("diary/timeline/reset", null, { root: true });
      this.orderItems();
    },
    onUpdated(data) {
      this.items = [
        ...this.items.filter((element) => element.id !== data.id),
        data,
      ];
      this.$store.dispatch("diary/timeline/reset", null, { root: true });
      this.orderItems();
    },
    orderItems() {
      this.items.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      });
    },
  },
};
</script>